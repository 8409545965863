module.exports = {
    achievements: [
      {
        id: 'public-speaking',
        title: 'Public Speaking',
        description: 'Giving back to the community by sharing knowledge during public events',
        url: '/public-speaking'
      },
      {
        id: 'beyondxscratch',
        title: 'BeyondxScratch',
        description: 'Personal technical blog on Domain-Driven Design, Hexagonal Architecture and Security',
        url: 'https://beyondxscratch.com'
      },
      {
        id: 'craftsrecords',
        title: 'CraftsRecords',
        description: 'Collective of enthusiasts wishing to contribute to the community by sharing their know-how through open-source repositories and public speaking. CraftsRecords also organizes a meetup helping people to become tech conference speakers',
        url: 'https://craftsrecords.org'
      }
    ],
  };