import React from 'react';

import Layout from '../components/Layout';

import config from '../../config';
import portfolio from '../data/portfolio'

const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <div className="logo">
          <span className="icon"></span>
        </div>
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
      </div>
    </section>

    <section id="wrapper">
      {
        portfolio.achievements.map((achievement, index) => {
          const styleClass = `wrapper ${index % 2 === 0 ? 'alt' : ''} spotlight style${index + 1}`
          return <section key={achievement.id} className={styleClass}>
            <div className="inner">
              <a href={achievement.url} className="image">
                <img src={require(`../assets/images/${achievement.id}.png`)} alt="" />
              </a>
              <div className="content">
                <h2 className="major">{achievement.title}</h2>
                <p>
                  {achievement.description}
                </p>
                <a href={achievement.url} className="special">
                  Learn more
            </a>
              </div>
            </div>
          </section>
        })
      }
    </section>
  </Layout>
);

export default IndexPage;
